<template>
  <!--验证码登录-->
  <div>
    <div class="column mt143">
      <div class="row align-center" >
          <img class="back" src="~images/sj.png" alt="">
          <input class="font28 f-999999 ml25 flex1 input" type="text" v-model="mobile" placeholder="请输入手机号">
      </div>
      <div class="line mt20" style="background-color: #F62341;"></div>
    </div>
    <!-- <div class="column mt60"> -->
      <div class="row align-center mt80">
          <img class="back" src="~images/yzm.png" alt="">
          <input class="font28 f-999999 ml25 flex1 input" type="text" v-model="sms_code" placeholder="请输入验证码">
          <div v-show="sendAuthCode" class="ml16 font28 f-2295F6 mr25" @click="getAuthCode">获取验证码</div>
          <div v-show="!sendAuthCode" class="ml16 font28 f-999999 mr25"><span class="auth_text_blue">{{auth_time}} </span> 秒后重新发送验证码</div>
      </div>
      <div class="line mt20" style="background-color: #F62341;"></div>
    <!-- </div> -->
    <div class="row flex-center mt143" @click="login"><span class="btn-red">登录</span></div>
  </div>
</template>

<script>
// @ is an alias to /src
import {auth_sms_code,sms_codes} from 'network/login'
export default {
  name: 'YanzhengLogin',
  data() {
    return {
     sms_code:'',
     mobile: '',
     sendAuthCode:true,/*布尔值，通过v-show控制显示‘获取按钮’还是‘倒计时’ */
     auth_time: 0, /*倒计时 计数器*/
    }
  },
 
  methods: {
    login() {
      if(!this.mobile) {
        this.$toast.show('手机号不能为空');
        return;
      }
      if(!this.sms_code) {
        this.$toast.show('验证码不能为空');
        return;
      }
      let params = {
        mobile: this.mobile,
        sms_code: this.sms_code
      }
      auth_sms_code(params).then(res=>{
        //this.$toast.show('登录成功');
        //1保存用户信息
        let token = res.data.token;
        localStorage.setItem('token',token);
        //返回上一页
        if(this.$route.query.redirect){
          this.$router.push({path:decodeURIComponent(this.$route.query.redirect)});
        }else{
          this.$router.back();
        }
      });
    },
    /**
     * 获取短信验证码
     */
    getAuthCode() {
      if(!this.mobile) {
        this.$toast.show('请输入手机号');
        return;
      }
      sms_codes(this.mobile).then(res=>{
        //console.log(res)
        //console.log('发送成功')
        //this.$toast.show('发送成功');
        this.sendAuthCode = false;
        this.auth_time = 60;
        var auth_timetimer =  setInterval(()=>{
            this.auth_time--;
            if(this.auth_time<=0){
                this.sendAuthCode = true;
                clearInterval(auth_timetimer);
            }
        }, 1000);
      },err=>{
        console.log(err)
      })
    },
  }
}
</script>
<style  scoped>
.login{
  background-color: #fff;
  height: 100vh;
}
.padding{
    padding: 0 .866667rem;
}
.back{
    width: .426667rem;
    height: .426667rem;
}
.underline{
    width:1.066667rem;
    height:.026667rem;
    background-color:rgba(246,35,65,1);
}
.input{
    border: none;
    outline: none;
    cursor: pointer;
}
.bubiao img{
    width: .426667rem;
    height: .426667rem;
}



</style>
